import { FIELDS, FlowStepsFieldsGroup } from "../../fields.d";
import {
  DOCUMENT_TYPES,
  FINANCIAL_ACCOUNTING_DOCUMENT_TYPES,
} from "./../../constants";

export type FormInputs = {
  id: number;
  name: string;
  isInitial: boolean;
  hiddenDocumentFields: string[];
  disabledDocumentFields: string[];
  isDeletable: boolean;
  organizationalUnit?: { id: number; label?: string }[];
  organizationalUnitId?: number;
  positionIds?: number[];
  positions?: { id: number; label?: string }[];
  positionsInOrganizationalUnit?: { id: number; label?: string }[];
  users?: { id: number; label?: string }[];
  groups?: { id: number; label?: string }[];
  userIds?: number[];
  documentFlowId: number;
  recipientType?: string;
  isPaymentCreationEnabled?: boolean;
  isLegalSectionEnabled?: boolean;
  isAllowedToEditAllFields?: boolean;
  documentFields?: { id: string; label?: string }[];
};

export const FLOW_STEPS_FIELDS: FlowStepsFieldsGroup[] = [
  {
    id: "basic",
    label: "Podstawowe informacje",
    fields: [
      {
        id: "name",
        label: "Nazwa",
        type: FIELDS.Input,
        span: 4,
        showStep: { visible: true },
        createStep: { visible: true, required: true },
        editStep: { visible: true, required: true },
      },
      {
        id: "documentFlow",
        label: "Nazwa procesu",
        type: FIELDS.Input,
        dataType: "model:flow",
        span: 4,
        showStep: { visible: true, accessor: ["documentFlow", "name"] },
        createStep: { visible: false },
        editStep: { visible: false },
      },
    ],
  },
  {
    id: "parameters",
    label: "Parametry",
    fields: [
      {
        id: "isInitial",
        label: "Początkowy krok obiegu",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 4,
        showStep: { visible: true },
        editStep: { visible: true },
        createStep: { visible: true },
      },
      {
        id: "isPaymentCreationEnabled",
        label: "Zezwalaj na utworzenie polecenia płatności",
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 4,
        showStep: { visible: true },
        editStep: { visible: true },
        createStep: { visible: true },
        documentType: FINANCIAL_ACCOUNTING_DOCUMENT_TYPES,
      },
      {
        id: "isLegalSectionEnabled",
        label:
          'Zezwalaj na edycję pól "Podstawa prawna" oraz "Pozostałe informacje"',
        type: FIELDS.Checkbox,
        dataType: "boolean",
        span: 4,
        showStep: { visible: true },
        editStep: { visible: true },
        createStep: { visible: true },
        documentType: [DOCUMENT_TYPES.RequestForm],
      },
    ],
  },
  {
    id: "recipients",
    label: "Odbiorcy",
    fields: [
      {
        id: "recipientType",
        label: "",
        type: FIELDS.RadioButton,
        span: 4,
        showStep: { visible: false },
        createStep: { visible: true },
        editStep: { visible: true },
      },
    ],
  },
];
