import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { KIND, SIZE } from "baseui/button";
import { Skeleton } from "baseui/skeleton";
import { LabelMedium } from "baseui/typography";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import {
  ArrowBigRightLines,
  Check,
  FileZip,
  HandGrab,
  HandOff,
  Lock,
  LockOpen,
  Pencil,
  Send,
  Trash,
  X,
} from "tabler-icons-react";

import Button from "../../../components/button";
import Cell from "../../../components/cell";
import ConfirmDialog, {
  ConfirmDialogType,
} from "../../../components/confirm-dialog";
import Content from "../../../components/content";
import DocumentBarcode from "../../../components/document-barcode";
import FlowActionModal from "../../../components/flow-action-modal";
import FlowProgress from "../../../components/flow-progress";
import FormControl from "../../../components/form-control";
import { DocumentFlowStartDialog } from "../../../components/form-dialog";
import FormattedValue from "../../../components/formatted-value";
import Grid from "../../../components/grid";
import Header from "../../../components/header";
import HistoryTable from "../../../components/history-table";
import PaymentItemsTable from "../../../components/payment-items-table";
import {
  DOCUMENT_STATES,
  HIDDEN_ADDRESS,
  NOT_FOUND_ERROR,
} from "../../../constants";
import { useAssignment } from "../../../contexts/assignment-context";
import { useAuth } from "../../../contexts/auth-context";
import { useDictionaries } from "../../../contexts/dictionaries-context";
import { useLoading } from "../../../contexts/loading-context";
import { useSubstitution } from "../../../contexts/substitution-context";
import { checkPermission } from "../../../utils/check-permission";
import {
  handleDataForProgressBar,
  handleHistoryFlowSteps,
} from "../../../utils/document-flow";
import { renderMutationSuccessLabel } from "../../../utils/documents/mutations-snackbar";
import { PERMISSIONS } from "../../../utils/permissions";
import {
  DocumentMutationAction,
  DocumentStatus,
} from "../../Documents/documents.d";
import {
  ADDRESS_REVEAL,
  DOCUMENT_FLOW_AVAILABLE_ACTIONS,
  DOCUMENTS_RELEASE_RESPONSIBLE,
  DOCUMENTS_SET_RESPONSIBLE,
  DOCUMENTS_TRACK_OPEN,
  DOCUMENTS_ZIP_EXPORT,
} from "../../Documents/documents.gql";
import Error404 from "../../ErrorPages/404";
import { DocumentFlowAction, HistoryFlowStep } from "../../Flow/document-flow";
import { AssignmentLocationState } from "../../Folders/folders";
import { PAYMENTS_FIELDS } from "../payments.form";
import { PAYMENTS_DELETE, PAYMENTS_SHOW } from "../payments.gql";

export default function PaymentsShow(): React.ReactElement {
  const [css, theme] = useStyletron();
  const { enqueueSnackbar } = useSnackbar();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const history = useHistory();
  const { id } = useParams<{
    id: string;
  }>();
  const documentId = parseInt(id);
  const { setIsLoading, isFetching, setIsFetching } = useLoading();
  const { user, refetch: userRefetch } = useAuth();
  const [isFlowActionModal, setIsFlowActionModal] = useState(false);
  const { state } = useLocation<AssignmentLocationState>();
  const { findValue } = useDictionaries();
  const [
    isAddressRevealConfirmDialogOpen,
    setIsAddressRevealConfirmDialogOpen,
  ] = useState(false);
  const [isAnyAddressRevealed, setIsAnyAddressRevealed] = useState(false);
  const [targetFlowAction, setTargetFlowAction] = useState<
    DocumentFlowAction | undefined
  >(undefined);
  const { assignment, setAssignment, setIsAssignmentActive } = useAssignment();

  const [flowStepsHistory, setFlowStepsHistory] = useState<HistoryFlowStep[]>(
    []
  );
  const [statusesForProgressBar, setStatusesForProgressBar] = useState<
    DocumentStatus[]
  >([]);
  const { substitution, isSubstitutionActive } = useSubstitution();

  const [
    isDocumentFlowStartDialogOpen,
    setIsDocumentFlowStartDialogOpen,
  ] = useState(false);

  const assignmentContext = {
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  };

  const { refetch, data, error } = useQuery(PAYMENTS_SHOW, {
    variables: { id: documentId },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });
  const legalAct = data?.payment?.documentRoot?.legalAct
    ? findValue(data?.payment?.documentRoot?.legalAct)
    : "";

  const {
    data: availableActionsData,
    refetch: availableActionsRefetch,
  } = useQuery(DOCUMENT_FLOW_AVAILABLE_ACTIONS, {
    variables: {
      documentId,
    },
    ...((state?.assignmentId || assignment?.id) &&
      state?.assignmentId !== null && {
        context: {
          headers: {
            Assignment: state?.assignmentId || assignment?.id,
          },
        },
      }),
  });

  const availableActionsButtons = availableActionsData?.documentFlowAvailableActions?.map(
    (action: DocumentFlowAction) => {
      return {
        label: action.label,
        kind: KIND.primary,
        startEnhancer:
          action.style === "positive" ? (
            <Check size={18} />
          ) : action.style === "negative" ? (
            <X size={18} />
          ) : (
            <Send size={18} />
          ),
        isPositive: action.style === "positive",
        isNegative: action.style === "negative",
        onClick: () => {
          setTargetFlowAction(action);
          setIsFlowActionModal(true);
        },
      };
    }
  );

  useEffect(() => {
    if (
      error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] !== NOT_FOUND_ERROR
    )
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  const hasFlowSteps = !!data?.payment?.currentStatus;
  const hasAnySignaturesBefore = statusesForProgressBar?.some(
    (status: DocumentStatus) => !!status.signatureDownloadUrl
  );
  const lastStatusWithSignature = statusesForProgressBar
    ?.filter((status: DocumentStatus) => !!status.signatureDownloadUrl)
    .pop();

  useEffect(() => {
    refetch();
  }, [data?.payment?.currentStatus]);

  const tables = data?.payment?.paymentItems
    .map((item: { tableId: string }) => item.tableId)
    .filter(
      (value: string, index: number, self: any) => self.indexOf(value) === index
    )
    .map((id: string) => ({
      id,
    }));

  const [trackOpen] = useMutation(DOCUMENTS_TRACK_OPEN, assignmentContext);

  const [revealAddress, { loading: revealAddressLoading }] = useMutation(
    ADDRESS_REVEAL,
    assignmentContext
  );

  const [exportZipFile] = useMutation(DOCUMENTS_ZIP_EXPORT, assignmentContext);

  const [deletePayment] = useMutation(PAYMENTS_DELETE, assignmentContext);

  const [setResponsible] = useMutation(
    DOCUMENTS_SET_RESPONSIBLE,
    assignmentContext
  );

  const [releaseResponsible] = useMutation(
    DOCUMENTS_RELEASE_RESPONSIBLE,
    assignmentContext
  );

  const documentMutationVariables = {
    variables: {
      documentId,
    },
  };

  const documentAddressMutationVariables = {
    variables: {
      documentId,
      addressId: data?.payment?.sender?.id,
    },
  };

  const onDocumentMutationSubmit = async (
    action: DocumentMutationAction
  ): Promise<void> => {
    setIsLoading(true);

    try {
      if (action === DocumentMutationAction.ExportZip) {
        const response = await exportZipFile(documentMutationVariables);

        const apiUrl = process.env.REACT_APP_GRAPHQL_API_URL?.replace(
          "/graphql",
          ""
        );

        window.open(
          `${apiUrl}/documents/download-zip-export/${id}/${response?.data?.documentExportZip}`,
          "_self"
        );
      }

      if (action === DocumentMutationAction.RevealAddress) {
        await revealAddress(documentAddressMutationVariables);

        setIsAnyAddressRevealed(true);
      }

      if (action === DocumentMutationAction.DeletePayment) {
        await deletePayment(documentMutationVariables);

        setIsConfirmDialogOpen(false);
        history.push("/payments");
      }

      if (action === DocumentMutationAction.ReleaseResponsible)
        await releaseResponsible(documentMutationVariables);

      if (action === DocumentMutationAction.SetResponsible)
        await setResponsible(documentMutationVariables);

      enqueueSnackbar({
        message: renderMutationSuccessLabel(action),
        variant: "success",
      });

      refetch();
    } catch (error: unknown) {
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
    } finally {
      setIsLoading(false);

      if (action === DocumentMutationAction.RevealAddress)
        setIsAddressRevealConfirmDialogOpen(false);
    }
  };

  useEffect(() => {
    if (
      error?.graphQLErrors &&
      error?.graphQLErrors &&
      (error as ApolloError).graphQLErrors?.map(
        ({ extensions }) => extensions?.code
      )[0] !== NOT_FOUND_ERROR
    )
      enqueueSnackbar({
        message: (error as ApolloError).graphQLErrors?.map(
          ({ message }) => message
        )[0],
        variant: "error",
      });
  }, [error]);

  useEffect(() => {
    if (data?.payment) refetch();
    if (availableActionsData?.documentFlowAvailableActions)
      availableActionsRefetch();
    setIsFetching(true);
    setStatusesForProgressBar([]);
    trackOpen({ variables: { id: documentId } }).then(() => userRefetch());
  }, []);
  useEffect(() => {
    if (availableActionsData) availableActionsRefetch();
  }, [data?.payment?.currentStatus]);

  useEffect(() => {
    if (data?.payment?.currentAssignment) {
      setAssignment(data?.payment?.currentAssignment);
      setIsAssignmentActive(true);
    }

    setFlowStepsHistory(handleHistoryFlowSteps(data?.payment?.statuses));
    setStatusesForProgressBar(
      handleDataForProgressBar(data?.payment?.statuses)
    );
    data?.payment && setIsFetching(false);
  }, [data]);

  if (!checkPermission(PERMISSIONS.payments.read)) {
    return <Redirect to="/" />;
  } else if (
    error?.graphQLErrors &&
    (error as ApolloError).graphQLErrors?.map(
      ({ extensions }) => extensions?.code
    )[0] === NOT_FOUND_ERROR
  ) {
    return <Error404 />;
  }
  return (
    <article>
      <Header
        title={data?.payment?.internalNumber}
        labels={[
          <FormattedValue
            key="label_1"
            dataType={"model:cases"}
            data={data?.payment?.case?.id}
            additionalData={data?.payment?.case?.isConductedElectronically}
            showBlankWhenEmpty
          >
            {`${data?.payment?.case?.number}`}
          </FormattedValue>,
          "Polecenie płatności",
        ]}
        goBackOption
        hasActiveAssignmentBar
        hasCancellationBar={data?.payment?.state === DOCUMENT_STATES.Canceled}
        buttons={[
          {
            label: "Przejmij",
            startEnhancer: <HandGrab size={18} />,
            kind: KIND.secondary,
            isPositive: true,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.SetResponsible),
            permission: data?.payment?.isResponsibleSettable,
          },
          {
            label: "Zwolnij",
            startEnhancer: <HandOff size={18} />,
            kind: KIND.secondary,
            onClick: () =>
              onDocumentMutationSubmit(
                DocumentMutationAction.ReleaseResponsible
              ),
            permission:
              data?.payment?.responsible?.id ===
                (isSubstitutionActive
                  ? substitution?.substitutee.id
                  : user?.id) && data?.payment?.isReleasable,
          },
          {
            label: "Rozpocznij obieg",
            startEnhancer: <ArrowBigRightLines size={18} />,
            kind: KIND.primary,
            isPositive: true,
            permission:
              data?.payment?.isEditable &&
              !data?.payment?.documentFlowId &&
              !!data?.payment?.documentFlow,
            onClick: () => setIsDocumentFlowStartDialogOpen(true),
          },
          ...(availableActionsButtons || []),
        ]}
        actions={[
          {
            label: "Edytuj polecenie płatności",
            icon: Pencil,
            permission:
              checkPermission(PERMISSIONS.payments.update) &&
              data?.payment?.isEditable,
            onClick: () =>
              history.push(
                `/payments/${id}/edit`,
                state || data?.payment?.currentAssignment?.id
                  ? { assignmentId: data.payment.currentAssignment?.id }
                  : undefined
              ),
          },
          {
            label: "Usuń polecenie płatności",
            icon: Trash,
            color: theme.colors.negative,
            onClick: () => setIsConfirmDialogOpen(true),
            permission:
              checkPermission(PERMISSIONS.payments.delete) &&
              data?.payment?.isEditable,
          },
          {
            label: "Pobierz plik ZIP",
            icon: FileZip,
            color: theme.colors.black,
            onClick: () =>
              onDocumentMutationSubmit(DocumentMutationAction.ExportZip),
          },
        ]}
      >
        <DocumentBarcode
          documentId={documentId}
          internalNumber={data?.payment?.internalNumber as string}
        />
      </Header>

      <Content>
        <Grid>
          <Cell span={12}>
            <>
              {hasFlowSteps &&
                availableActionsData &&
                (isFetching ? (
                  <Skeleton rows={0} height="150px" width="100%" animation />
                ) : (
                  <FlowProgress
                    currentStep={data?.payment?.currentStatus?.documentFlowStep}
                    originalOrganizationalUnitName={
                      data?.payment?.organizationalUnit?.name
                    }
                    statuses={statusesForProgressBar}
                    actions={availableActionsData.documentFlowAvailableActions}
                  />
                ))}
            </>
          </Cell>
          {PAYMENTS_FIELDS.filter(
            (g) => g.fields.filter((f) => f.show.visible).length > 0
          ).map((group) => [
            group.label && (
              <Cell key={group.id + `-group`} span={12}>
                <>
                  <Block
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="scale200"
                    marginTop="scale600"
                  >
                    <Block
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      {data?.financialAccountingDocument?.sender?.__typename ===
                        HIDDEN_ADDRESS && group.id === "sender" ? (
                        <Lock
                          size={18}
                          color={theme.colors.negative}
                          className={css({ marginRight: "10px" })}
                        />
                      ) : (
                        group.id === "sender" && (
                          <LockOpen
                            size={18}
                            color={theme.colors.positive}
                            className={css({ marginRight: "10px" })}
                          />
                        )
                      )}
                      <LabelMedium>{group.label}</LabelMedium>
                      {data?.financialAccountingDocument?.sender?.__typename ===
                        HIDDEN_ADDRESS &&
                        group.id === "sender" && (
                          <Button
                            size={SIZE.mini}
                            kind={KIND.secondary}
                            $style={{ marginLeft: "10px" }}
                            onClick={() =>
                              setIsAddressRevealConfirmDialogOpen(true)
                            }
                            isLoading={revealAddressLoading}
                          >
                            Pokaż dane
                          </Button>
                        )}
                    </Block>
                    {data?.financialAccountingDocument?.sender?.__typename !==
                      HIDDEN_ADDRESS &&
                      isAnyAddressRevealed &&
                      group.id === "sender" && (
                        <FormattedValue
                          dataType="pre"
                          $style={{ fontSize: "13px" }}
                        >
                          Dostęp jest aktywny do końca trwania sesji
                        </FormattedValue>
                      )}
                  </Block>
                  <hr
                    className={css({
                      borderWidth: "0px",
                      height: "1px",
                      backgroundColor: "#eee",
                    })}
                  />
                </>
              </Cell>
            ),
            group.fields
              .filter((f) => f.show.visible)
              .map((item, index) => (
                <Cell span={item.span || 6} key={group.id + `-field` + index}>
                  <FormControl label={item.label} disabled={true}>
                    {isFetching ? (
                      <Skeleton rows={0} height="20px" width="100%" animation />
                    ) : (
                      <FormattedValue
                        dataType={item.dataType}
                        data={
                          item.id === "financialAccountingDocument"
                            ? `${data?.payment?.financialAccountingDocument?.documentKind?.toLowerCase()}/FinancialAccountingDocument/${
                                data?.payment?.financialAccountingDocument?.id
                              }`
                            : item.id === "organizationalUnit"
                            ? data?.payment?.organizationalUnit?.id
                            : item.dataType === "model:users"
                            ? data?.payment[item.id]?.id
                            : data?.payment[item.id]
                        }
                        currency={
                          data?.payment?.financialAccountingDocument?.currency
                            ?.code
                        }
                      >
                        {item.dataType === "model:users"
                          ? `${data?.payment[item.id]?.firstName} ${
                              data?.payment[item.id]?.lastName
                            }`
                          : item.id === "legalAct"
                          ? legalAct
                          : item.id === "invoiceType"
                          ? findValue(
                              data?.payment?.financialAccountingDocument
                                ?.invoiceType
                            )
                          : item.id === "addressAddress"
                          ? data?.payment?.financialAccountingDocument?.sender
                              ?.streetName
                            ? `${
                                data?.payment?.financialAccountingDocument
                                  ?.sender?.streetName
                              } ${
                                data?.payment?.financialAccountingDocument
                                  ?.sender?.buildingNumber
                              }${
                                data?.payment?.financialAccountingDocument
                                  ?.sender?.apartmentNumber
                                  ? "/" +
                                    data?.payment?.financialAccountingDocument
                                      ?.sender?.apartmentNumber
                                  : ""
                              }`
                            : data?.financialAccountingDocument?.sender?.address
                          : item.info
                          ? item.info
                          : item.show.accessor
                          ? item.show.accessor.length === 3
                            ? data?.payment[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]?.[item.show.accessor[2]]
                            : data?.payment[item.show.accessor[0]]?.[
                                item.show.accessor[1]
                              ]
                          : data?.payment[item.id]}
                      </FormattedValue>
                    )}
                  </FormControl>
                </Cell>
              )),
          ])}
          <Cell span={12}>
            <Block
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <LabelMedium marginBottom="scale200" marginTop="scale600">
                Przelewy
              </LabelMedium>
              <Block display="flex" justifyContent="flex-end">
                {isFetching ? (
                  <Skeleton rows={0} height="20px" width="200px" animation />
                ) : (
                  <LabelMedium marginBottom="scale200" marginTop="scale600">
                    Kwota z dokumentu F-K:{" "}
                    <FormattedValue
                      dataType="quota"
                      currency={
                        data?.payment?.financialAccountingDocument?.currency
                          ?.code
                      }
                    >
                      {data?.payment?.financialAccountingDocument?.grossValue}
                    </FormattedValue>
                  </LabelMedium>
                )}
              </Block>
            </Block>
            <hr
              className={css({
                borderWidth: "0px",
                height: "1px",
                backgroundColor: "#eee",
              })}
            />
          </Cell>
          {tables?.length ? (
            tables?.map((table: { id: string }) => (
              <Cell span={12} key={table?.id}>
                {isFetching ? (
                  <Skeleton rows={0} height="200px" width="100%" animation />
                ) : (
                  <Block paddingTop="scale200" paddingBottom="scale600">
                    <PaymentItemsTable
                      paymentItems={data?.payment?.paymentItems}
                      tableId={table.id}
                      currency={
                        data?.payment?.financialAccountingDocument?.currency
                      }
                    />
                  </Block>
                )}
              </Cell>
            ))
          ) : (
            <Cell span={12}>
              {isFetching ? (
                <Skeleton rows={0} height="200px" width="100%" animation />
              ) : (
                <Block paddingTop="scale200" paddingBottom="scale600">
                  <PaymentItemsTable
                    paymentItems={[]}
                    tableId={null}
                    currency={
                      data?.payment?.financialAccountingDocument?.currency
                    }
                  />
                </Block>
              )}
            </Cell>
          )}
          {hasFlowSteps && (
            <>
              <Cell span={12}>
                <LabelMedium marginBottom="scale200" marginTop="scale600">
                  Historia obiegu dokumentu{" "}
                  {!flowStepsHistory?.length && "(brak)"}{" "}
                </LabelMedium>
                <hr
                  className={css({
                    borderWidth: "0px",
                    height: "1px",
                    backgroundColor: "#eee",
                  })}
                />
              </Cell>
              <Cell span={12}>
                {isFetching ? (
                  <Skeleton rows={0} height="200px" width="100%" animation />
                ) : flowStepsHistory?.length ? (
                  <Block paddingTop="scale200" paddingBottom="scale600">
                    <HistoryTable
                      documentStatuses={data?.payment?.statuses}
                      flowStepsHistory={flowStepsHistory}
                    />
                  </Block>
                ) : (
                  <></>
                )}
              </Cell>
            </>
          )}
          <ConfirmDialog
            isOpen={isConfirmDialogOpen}
            label={`Usunięcie polecenia płatności dla dokumentu ${
              data?.payment?.financialAccountingDocument?.internalNumber
            } ${
              data?.payment?.financialAccountingDocument?.documentNumber
                ? `(numer ${data?.payment?.financialAccountingDocument?.documentNumber})`
                : ""
            }`}
            close={() => setIsConfirmDialogOpen(false)}
            confirm={() =>
              onDocumentMutationSubmit(DocumentMutationAction.DeletePayment)
            }
          />
          {targetFlowAction && (
            <FlowActionModal
              documentId={documentId}
              action={targetFlowAction}
              hasAnySignaturesBefore={hasAnySignaturesBefore}
              isOpen={isFlowActionModal}
              label={
                data?.payment?.financialAccountingDocument?.internalNumber
                  ? `Polecenie płatności dla dokumentu ${
                      data?.payment?.financialAccountingDocument?.internalNumber
                    } ${
                      data?.payment?.financialAccountingDocument?.documentNumber
                        ? `(numer ${data?.payment?.financialAccountingDocument?.documentNumber})`
                        : ""
                    }`
                  : ""
              }
              lastStatusWithSignature={lastStatusWithSignature}
              reloadPage={refetch}
              setIsOpen={setIsFlowActionModal}
            />
          )}

          <DocumentFlowStartDialog
            isOpen={isDocumentFlowStartDialogOpen}
            close={() => setIsDocumentFlowStartDialogOpen(false)}
            onCompleted={() => {
              setAssignment(undefined);
              history.push(location.pathname, { assignmentId: null });
            }}
            documentId={documentId}
          />

          <ConfirmDialog
            isOpen={isAddressRevealConfirmDialogOpen}
            type={ConfirmDialogType.AddressReveal}
            close={() => setIsAddressRevealConfirmDialogOpen(false)}
            confirm={() =>
              onDocumentMutationSubmit(DocumentMutationAction.RevealAddress)
            }
          />
        </Grid>
      </Content>
    </article>
  );
}
